import React from "react";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {
  AccessTime,
  AssignmentInd,
  CalendarMonth,
  Dashboard,
  EventNote,
  FormatIndentDecrease,
  FormatIndentIncrease,
  Person,
  PhoneAndroid,
  RoomPreferences
} from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
import WebURL from "../../urls";
import {isEligibleAction} from "@/utils/permissions/FilterUnauthed"
import {ReactComponent as AccDevice} from "@/assets/images/AccDevice.svg";
import {ReactComponent as AccDeviceAction} from "@/assets/images/AccDeviceAction.svg";
import {ReactComponent as SiteManagement} from "@/assets/images/SiteManagement.svg";
import {ReactComponent as SiteManagementAction} from "@/assets/images/SiteManagementAction.svg";
import Prefix from "@/utils/static/NamePrefix"
import { makeStyles } from '@mui/styles';

const useStyles  = makeStyles({
  sideBarItem: {
      backgroundColor: 'rgb(223, 245, 231) !important',
      borderInlineEnd: "4px solid #36C96D"
  },
  sideBarItemHover: {
  "&:hover": {
    backgroundColor: 'rgb(223, 245, 231) !important'
  }
}
});

type MenuProps = {
  key: string;
  title: string;
  icon: any;
  path: string;
  authed?: string;
}
const SideBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [contextMenu, setContextMenu] = React.useState(true);
  const classes = useStyles();
  const menus: MenuProps[] = [
    { key: 'dashboard', title: t("common.sidebar.Dashboard"), icon: Dashboard, path: WebURL.DASHBOARD },
    { key: 'hr', title: t("common.sidebar.Organization"), icon: Person, path: WebURL.ORGANIZATION_HOME },
    { key: 'location', title: t("common.sidebar.Site Management"), path: WebURL.LOCATION_HOME,
        icon: (location.pathname.startsWith(WebURL.LOCATION_HOME)) ? SiteManagementAction : SiteManagement},
    { key: 'device', authed: "DEVICE_LIST", title: t("dms.Device"), path: WebURL.DMS_DEVICE,
        icon: (location.pathname.startsWith(WebURL.DMS_DEVICE)) ? AccDeviceAction : AccDevice},
    { key: 'rule', title: t("common.sidebar.Attendance Rule"), icon: CalendarMonth, path: WebURL.ATT_RULE_HOME },
    // { key: 'schedule', authed: "SCHEDULE_LIST", title: t("common.sidebar.Schedule"), icon: AssignmentInd, path: WebURL.ATT_SCHEDULE },
    { key: 'timecard', title: t("common.sidebar.Timecard"), icon: AccessTime, path: WebURL.ATT_TIMECARD_HOME },
    { key: 'report', title: t("common.sidebar.Report"), icon: EventNote, path: WebURL.ATT_REPORT_HOME },
    // { key: 'subscription', title: "Subscription", icon: SettingsIcon, path: WebURL.SUBSCRIPTION_HOME },
  ]
  const onMenuClick = (item: MenuProps) => {
    navigate(item.path)
  }
  return (
    <Grid style={{
      display: 'flex',
      padding: '8px 0px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      position: 'relative',
      boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036)',
    }}>
      <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%', minWidth: contextMenu ? "70px" : "240px", transition: "all 250ms ease-in-out", wordBreak: "break-all", wordWrap: "break-word"}}>
        {
          menus.map((item, index) => {
            if (!isEligibleAction(item?.authed)){ // 未拥有查看权限
              return (<></>)
            }
            return (
              <Grid key={item.key} display={"flex"} onClick={() => onMenuClick(item)} className={location.pathname.startsWith(item.path) ? classes.sideBarItem : classes.sideBarItemHover} >
                {
                  contextMenu
                    ? <Tooltip title={item.title} placement="right" enterDelay={500}>
                      <IconButton
                        disableRipple
                        id={`${Prefix.SIDEBAR_ICON}${item.key}`}
                        style={{ height: '50px', width: contextMenu ? "100%" : "70px" }}
                        children={<item.icon
                          color={
                            location.pathname.startsWith(item.path)
                              ? "primary"
                              : "action"
                          }
                          style={{ width: '20px', height: '20px' }} />
                        }
                      />
                    </Tooltip>
                    : <IconButton
                      disableRipple
                      id={`${Prefix.SIDEBAR_ICON}${item.key}`}
                      style={{ height: '50px', width: contextMenu ? "100%" : "70px" }}
                      children={<item.icon
                        color={
                          location.pathname.startsWith(item.path)
                            ? "primary"
                            : "action"
                        }
                        style={{ width: '20px', height: '20px' }} />}
                    />
                }
                <Grid
                  key={index}
                  id={`${Prefix.SIDEBAR_TEXT}${item.key}`}
                  style={{
                    display: contextMenu ? "none" : "flex",
                    width: '100%',
                    fontSize: '18px',
                    color:  location.pathname.startsWith(item.path) ? '#36C96D' : '#304669',
                    alignItems: 'center',
                    height: '50px',
                    cursor: 'pointer',
                    fontFamily: 'Roboto',
                    paddingRight: '8px'
                  }}
                >
                  {item.title}
                </Grid>
              </Grid>
            )
          })
        }
        <IconButton
          id='FormatIndentIconButton'
          style={{
            borderRadius: '8px',
            backgroundColor: '#a8b8d126',
            height: '50px',
            bottom: '14px',
            left: '14px',
            position: 'absolute'
          }}
          onClick={() => setContextMenu(!contextMenu)}
          disableRipple>
          {
            contextMenu
              ? <FormatIndentIncrease style={{ opacity: '0.8', fill: '#304669' }} />
              : <FormatIndentDecrease style={{ opacity: '0.8', fill: '#304669' }} />
          }
        </IconButton>
        {!contextMenu && <Typography
          style={{
            // height: '50px',
            bottom: '30px',
            left: '64px',
            position: 'absolute'
          }}
        >
          v2.2.0
        </Typography>}

      </Grid>
    </Grid>
  )
};
export default SideBar
