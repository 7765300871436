import React, { Fragment, useContext, useEffect, useState } from "react";
import {Avatar, Box, Grid, TextField, Tooltip} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import CustomDataGrid from "../../../../components/data-grid/CustomDataGrid";
import {ATTEndpoints} from "../../../../services/att/endpoints";
import WebURL from "../../../../urls";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import { useTranslation } from "react-i18next";
import FirstAndLastService from "@/services/att/services/FirstAndLastService";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Util from "@/utils/Util";
import { DataGridContext, DataGridDispatchContext } from "@/components/data-grid/DataGridContext";
import ExportAction from "@/components/actions/Export";
import {isEligibleAction} from "@/utils/permissions/FilterUnauthed"
import SnackbarUtil from "@/utils/SnackbarUtil";

const FIRSTANDLAST_PERMISSION_NAME = "FIRSTLAST_EXPORT"

const ExtraAction = (props: any) => {
  const {t} = useTranslation();
  const [month, setMonth] = React.useState<Date | null>(new Date())
  const dispatch = useContext(DataGridDispatchContext)
  const { searchKeyword } = useContext(DataGridContext)
  const handleRefresh = () => {
    dispatch({type: "refreshed"})
  }
  const hasExportPermission = isEligibleAction(FIRSTANDLAST_PERMISSION_NAME)
  return (
    <Fragment>
      <Grid item={true}>
        <Box>
          <Grid
            container={true}
            columnSpacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "200px", paddingLeft: "10px" }}>
              <Tooltip title={t('common.Date Picker')} placement="bottom-end">
              <div>
              <DatePicker
                disableFuture
                views={['year', 'month']}
                value={month}
                onChange={(newValue: any) => {
                  setMonth(newValue)
                  if (props.setFilterParams) {
                    props.setFilterParams({...props?.filterParams, ...{month: Util.datetime2string(newValue, "yyyy-MM")}})
                  }
                  handleRefresh()
                }}
                slotProps={{
                  textField: {
                    size: "small"
                  }
                }}
              />
              </div>
              </Tooltip>
            </Box>
          </Grid>
        </Box>
      </Grid>
      {hasExportPermission && <Grid item={true}>
        <ExportAction setExportFormat={(fileType: string) => { props.setFilterParams({ ...props?.filterParams, keyword: searchKeyword }); props.setExportFormat(fileType) }}  />
      </Grid>
      }
    </Fragment>
  )
}


const FirstAndLast: React.FC = () => {
  const {t} = useTranslation()
  const [filterParams, setFilterParams] = useState<Record<any, any>>({});
  const [exportFormat, setExportFormat] = useState<"xlsx" | "csv" | null>(null);

  useEffect(() => {
    if (exportFormat) {
      const fields: Array<{name: string, alias: string}> = []
      for (const column in columns) {
        fields.push({name: columns[column]["field"], alias: columns[column]["headerName"]!})
      }
      const export_meta = {
        fields,
        title: t("att.report.First And Last"),
        file_type: exportFormat,
        share: []
      }
      const params: string[] = []
      for (const key in filterParams) {
        params.push(`${key}=${filterParams[key]}`)
      }
      FirstAndLastService.exportData(params.join("&"), export_meta
        ).then((response) => {
          console.log("lod", response, response.headers["content-type"])
        const blob = new Blob([response.data], {type: response.headers["content-type"]})
        const url = URL.createObjectURL(blob)
        const aLink = document.createElement("a")
        aLink.style.display = "none"
        aLink.href = url
        document.body.appendChild(aLink)
        aLink.click()
        URL.revokeObjectURL(url)
        document.body.removeChild(aLink)
      }).catch((error) => {
        if (error.response.status < 500) {
          // do something when status is 4xx.
        }
      });
      setExportFormat(null)
    }
  }, [exportFormat])

  const columns: GridColDef[] = [
    {field: 'employee_code', headerName: t("common.Person ID"), type: "string", minWidth: 120, flex: 0.2},
    {
      field: 'employee_name', headerName: t("common.Person Name"), minWidth: 220, flex: 0.3, renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            {/* <Avatar src={"https://joeschmoe.io/api/v1/random"} style={{width: '32px', height: '32px'}}/> */}
            <AvatarIcon diameter={20} name={params.row.employee_name ? params.row.employee_name : "None"} style={{ padding: "10px" }}/>
            <Box style={{padding: '0 8px'}}>{params.row.employee_name}</Box>
          </Grid>
        )
      }
    },
    {field: 'att_date', headerName: t("att.report.Date"), type: "string", minWidth: 150, flex: 0.2},
    {field: 'timesheet_name', headerName: t("att.report.Timesheet Name"), type: "string", minWidth: 200, flex: 0.2},
    {field: 'first_punch', headerName: t("att.report.First Clocking"), type: "string", minWidth: 180, flex: 0.2},
    {field: 'last_punch', headerName: t("att.report.Last Clocking"), type: "string", minWidth: 180, flex: 0.2},
    {field: 'total', headerName: t("att.report.Total Work Hours"), type: "string", minWidth: 180, flex: 0.2},
  ]
  return (
    <CustomDataGrid
      uri={ATTEndpoints.ATT_REPORT_FIRST_LAST}
      columns={columns}
      checkboxSelection={false}
      requestParams={filterParams}
      noResultFound={t("att.report.No first and last report found")}
      getRowId={(row) => {
        return Math.random()
      }}
      toolbar={{
        title: t("att.report.First And Last"),
        authedAction: FIRSTANDLAST_PERMISSION_NAME,
        search: true,
        searchPlaceholder: t('att.report.Search by Person, Date'),
        breadcrumbs: [
          {breadcrumbName: t("att.report.Report"), path: WebURL.ATT_REPORT_HOME},
          {breadcrumbName: t("att.report.First And Last"), path: WebURL.ATT_REPORT_FIRST_LAST},
        ],
        children: <ExtraAction filterParams={filterParams} setFilterParams={setFilterParams} setExportFormat={setExportFormat} />
      }}
    />
  )
}
export default FirstAndLast;
