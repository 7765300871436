import React, { useRef, Dispatch, SetStateAction } from "react";
import {Box, Button, Grid, Stack, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { object, TypeOf, z } from "zod";

type FilterProps = {
  show: boolean;
  onSubmit: (params: any) => Promise<void>;
  setParams: Dispatch<SetStateAction<Record<any, any>>>;
  refresh?: (resetPage?: boolean|undefined) => void
}

const EmployeeFilterFC: React.FC<FilterProps> = (props) => {
  const {t} = useTranslation()

  const filterFormValidate = object({
    code: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
  })
  type FilterForm = TypeOf<typeof filterFormValidate>;
  const filterForm = useForm<FilterForm>({
    defaultValues: {code: '', firstName: "", lastName: ""}
  });
  const {
    register,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = filterForm;

  React.useEffect(() => {

    const refreshPage = () => {
      filterForm.reset()
      props.setParams({})
      props.refresh && props.refresh(true)
    };

    // if(props.show === true) {
    //   refreshPage();
    // }

    refreshPage();

  }, [!props.show]);

  if (!props.show) {
    return (
      <></>
    )
  }

  return (
    <>
      <Grid container={true} component={'form'} onSubmit={handleSubmit(props.onSubmit)}
        sx={{borderRadius: '8px',
            padding: { xl: '15px', xs: "10px" },
            width: '100%',
            border: '1px solid #EAEBEB',
          }}>
        <Grid item xs={12} md={2.4} sx={{ paddingRight: { md: "20px", xs: "0px" }, }}>
          <Box sx={{ width: '100%' }} >
            <Box display={'flex'} sx={{padding: "10px"}}>
              <Controller
                control={control}
                name="code"
                render={({ field }) => (
                  <TextField
                  sx={{paddingRight: "50px", width: "400px"}}
                    label={t("common.Person ID")}
                    value={field.value}
                    size="small"
                    error={!!errors.code}
                    helperText={errors.code?.message || ""}
                    {...register("code")}
                  />
                )}
              />
              </Box>
            </Box>
        </Grid>
        <Grid item xs={12} md={2.4} sx={{ paddingRight: { md: "20px", xs: "0px" }, }}>
          <Box display={'flex'} sx={{padding: "10px"}}>
            <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                  sx={{paddingRight: "50px", width: "400px"}}
                    label={t("signUp.First Name")}
                    value={field.value}
                    size="small"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message || ""}
                    {...register("firstName")}
                  />
                )}
              />
          </Box>
        </Grid>
        <Grid item xs={12} md={2.4} sx={{ paddingRight: { md: "20px", xs: "0px" }, }}>
          <Box sx={{ width: '100%' }} >
            <Box display={'flex'} sx={{padding: "10px"}}>
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <TextField
                  sx={{paddingRight: "50px", width: "400px"}}
                    label={t("signUp.Last Name")}
                    value={field.value}
                    size="small"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message || ""}
                    {...register("lastName")}
                  />
                )}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={2.4} sx={{ paddingRight: { md: "20px", xs: "0px" }, }}>
          <Box sx={{ width: '100%' }} >
            <Box display={'flex'} sx={{padding: "10px"}}>  </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={2.4}
          sx={{
            paddingLeft: { md: "20px", xs: "0px" },
            mt: { md: "0px", xs: "20px" },
            display: "flex",
            justifyContent: { md: "flex-end", xs: "center" },
          }}
          >
          <Box display={'flex'} sx={{padding: "10px"}}>
            <Button
              id='SearchButton'
              variant={"contained"}
              type='submit'
              sx={{
                marginRight: "20px",
                width: { xl: '100px', xs: "100px" },
                height: { xl: '40px', xs: "35px" },
                }}
              >
              {t("common.Search")}
            </Button>
            <Button
              sx={{
                width: { xl: '100px', xs: "100px" },
                height: { xl: '40px', xs: "35px" },
                }}
                id='ResetButton'
                variant={"outlined"}
                onClick={() => {
                  filterForm.reset()
                  props.setParams({})
                  setTimeout(() => {
                    console.log("reload");
                    props.refresh && props.refresh(true);
                  },
                   1000)

                }}
                >
                {t("common.Reset")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default EmployeeFilterFC;
